














































import { translate } from '@consolidate/shared/util-translations';
import Vue from 'vue';
import BoardsFacade from '../services/BoardsFacade';
export default Vue.extend({
  metaInfo: {
    title: translate('KANBAN'),
  },
  created() {
    BoardsFacade.loadBoards();
  },
  data: () => ({
    boardTitle: '',
    loading: false,
    showMenu: false,
  }),
  computed: {
    boards() {
      return BoardsFacade.boards;
    },
  },
  methods: {
    openBoard(id: number) {
      this.$router.push({
        name: 'kanban-board',
        params: { id: id.toString() },
      });
    },
    removeBoard(id: number) {
      BoardsFacade.deleteBoard(id);
    },
    async addBoard() {
      this.loading = true;
      await BoardsFacade.addBoard(this.boardTitle);
      this.boardTitle = '';
      this.loading = false;
      this.showMenu = false;
    },
  },
});
