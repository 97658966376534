

















































import Vue, { PropType } from 'vue';
import { normalizeColor } from '@consolidate/shared/ui-components';
import { CardDto } from '../api/gen';
import BoardsFacade from '../services/BoardsFacade';

export default Vue.extend({
  props: {
    item: { type: Object as PropType<CardDto>, required: true },
  },
  computed: {
    icon(): string {
      return `Activity${this.item.type.icon}`;
    },
    color(): string | null {
      return normalizeColor(this.item.type.color);
    },
  },
  methods: {
    async remove() {
      await BoardsFacade.removeCard(this.item.id);
    },
    openCard() {
      BoardsFacade.openActivity(this.item.id);
    },
  },
});
