































































import Vue from 'vue';
import BoardsFacade from '../services/BoardsFacade';
import moment from 'moment';
import Column from '../components/Column.vue';
import { translate } from '@consolidate/shared/util-translations';

export default Vue.extend({
  metaInfo() {
    const title: string = this.board?.title ?? '';

    return {
      title: `${translate('KANBAN')} | ${title}`,
    };
  },
  components: { Column },
  data: () => ({
    interval: {} as NodeJS.Timeout,
    lastUpdate: '',
    activityId: '',
    loading: false,
    error: false,
    adding: false,
    columnName: '',
  }),
  computed: {
    board() {
      return BoardsFacade.board;
    },
  },
  created() {
    BoardsFacade.load(+this.$route.params.id);

    this.setLastUpdate();
    this.interval = setInterval(() => {
      this.setLastUpdate();
    }, 5000);
  },
  destroyed() {
    clearInterval(this.interval);
  },
  methods: {
    setLastUpdate() {
      this.lastUpdate = moment(BoardsFacade.lastUpdate).fromNow();
    },
    async addCard() {
      this.loading = true;
      try {
        await BoardsFacade.addCard(+this.activityId);
        this.activityId = '';
      } catch (error) {
        this.error = true;
      }
      this.loading = false;
    },
    async addColumn() {
      await BoardsFacade.addColumn(this.columnName);
      this.adding = false;
      this.columnName = '';
    },
    cancelAdding() {
      this.adding = false;
      this.columnName = '';
    },
  },
});
