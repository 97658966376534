





















































import { SortableEvent } from 'sortablejs';
import Vue, { PropType } from 'vue';
import draggable from 'vuedraggable';
import { ColumnDto } from '../api/gen';
import Card from '../components/Card.vue';
import BoardsFacade from '../services/BoardsFacade';

export default Vue.extend({
  components: {
    draggable,
    Card,
  },
  props: {
    column: { type: Object as PropType<ColumnDto>, required: true },
  },
  data() {
    return {
      editing: false,
      columnName: this.column.title,
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
      };
    },
  },
  methods: {
    cardMoved(e: SortableEvent) {
      const oldColumn = parseInt(e.from.dataset.id ?? '');
      const newColumn = parseInt(e.to.dataset.id ?? '');

      const oldIndex = e.oldIndex ?? 0;
      const newIndex = e.newIndex ?? 0;

      BoardsFacade.move(
        { column: oldColumn, index: oldIndex },
        { column: newColumn, index: newIndex }
      );
    },
    async remove() {
      await BoardsFacade.removeColumn(this.column.id);
    },
    async edit() {
      await BoardsFacade.editColumn(this.column.id, this.columnName);
      this.editing = false;
    },
  },
});
